// eslint-disable-next-line
import React from "react";

function Home() {
    return (
        <div>
            <p>Hello</p>
        </div>
    );
}

export default Home;
